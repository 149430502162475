<template>
  <div class="technology main-wrapper">
    <img :src="imageList[0].imgUrl" />
    <div class="team-wrapper" :ref="'team'" 
        :style="{'background-image': `url(${imageList[1].imgUrl})`}">
      <!-- <img class="bg-img left" src="../assets/images/technology/bg-left.webp"> -->
      <!-- <img class="bg-img right" src="../assets/images/technology/bg-right.webp"> -->
      <div class="member-list">
        <img
          v-for="(img, idx) in memberList"
          class="item first-item"
          @click="handleDetail(idx + 1)"
          :key="idx"
          :src="img.imgUrl"
        />
      </div>
      <div>
        <div class="custom-title" :class="[$store.getters.getLanguage === '' ? '' : 'EN']">
            {{ $store.getters.getLanguage === '' ? '博士天团' : 'Doctoral Team' }}
        </div>
        <div class="custom-content" :class="[$store.getters.getLanguage === '' ? '' : 'EN']">
            {{ $store.getters.getLanguage === '' ? 
                '华睿研发团队堪称一支全球化的博士天团，主要成员来自英国诺丁汉大学、美国弗吉尼亚理工大学、荷兰阿姆斯特丹大学、上海交通大学、中国国家生化工程中心等国内外知名大学和研究机构，关键负责人更是合成生物学届的领军人物。' : 
                `The R&D team of SENCL is a international doctoral team, with main members from the University of Nottingham (UK), Virginia Tech (USA), University of Amsterdam
 (Holland), Shanghai Jiao Tong University (China), and the National Engineering Research Center for Biotechnology (China), and other renowned domestic and international universities and research institutes, and the key persons in charge of the team are even the leaders of the synthetic biology industry.` }}
            
        </div>
      </div>
      <!-- <div class="member-list">
        <img
          v-for="img in memberList.second"
          class="item second-item"
          @click="handleDetail(img.index)"
          :key="img.index"
          :src="require('../assets/images/technology/member/' + img.url)"
        />
      </div> -->
    </div>
    <img :src="imageList[2].imgUrl" />
  </div>
</template>

<script>
import { getCosUrl } from "../utils";
export default {
  name: "technology",
  data() {
    return {
    //   memberList: {
    //     first: [
    //       {
    //         url: "1-2.webp",
    //         index: 1,
    //       },
    //       {
    //         url: "2-2.webp",
    //         index: 2,
    //       },
    //     ],
    //     second: [
    //       {
    //         url: "3-2.webp",
    //         index: 3,
    //       },
    //       {
    //         url: "4-2.webp",
    //         index: 4,
    //       },
    //       {
    //         url: "5-2.webp",
    //         index: 5,
    //       },
    //       {
    //         url: "6-2.webp",
    //         index: 6,
    //       },
    //       {
    //         url: "7-2.webp",
    //         index: 7,
    //       },
    //     ],
    //   },
        memberList: [
            { imgKey: "/technology/members/1.webp", imgUrl: "" },
            { imgKey: "/technology/members/2.webp", imgUrl: "" },
        ],
        imageList: [
            { imgKey: "/technology/1.webp", imgUrl: "" },
            { imgKey: "/technology/2.webp", imgUrl: "" },
            { imgKey: "/technology/3.webp", imgUrl: "" },
        ]
    };
  },
  watch: {
    "$store.state.device": {
        handler(newVal, oldVal){
            if(newVal != oldVal){
                this.initImage();
                this.initMembers();
            }
        },
        deep: true
    },
    "$store.state.language": {
        handler(newVal, oldVal){
            if(newVal != oldVal){
                this.initImage();
                this.initMembers();
            }
        },
        deep: true
    }
  },
  methods: {
    initImage(){
        const imgList = getCosUrl(this.imageList.map(item => { return item.imgKey }));
        this.imageList.forEach((element, index) => {
            element.imgUrl = imgList[index];
        })
    },
    initMembers(){
        const imgList = getCosUrl(this.memberList.map(item => { return item.imgKey }));
        this.memberList.forEach((element, index) => {
            element.imgUrl = imgList[index];
        })
    },
    handleDetail(index) {
      this.$router.push({
        name: "MemberDetail",
        params: {
          index: index,
        },
      });
    },
  },
  mounted() {
    this.initImage();
    this.initMembers();
    if(this.$route.params.isBack){
        this.$refs['team'].scrollIntoView();
    }
  },
};
</script>

<style lang="less">
div.technology {
    flex-direction: column;
    display: flex;
    div.team-wrapper {
        background-repeat: no-repeat;
        // justify-content: center;
        flex-direction: column;
        align-items: center;
        position: relative;
        overflow: hidden;
        display: flex;
        width: 100%;

        @media screen and (min-device-width: 1200px) {
            background-size: 100% 102.7rem;
            height: 102.7rem;
        }
        @media screen and (max-device-width: 1199px) {
            background-size: 100% 173.3333vw;
            height: 173.3333vw;
        }

        // .bg-img {
        //   // transform: rotate(-90deg);
        //   width: 40% !important;
        //   position: absolute;
        //   &.left {
        //     left: -20%;
        //     bottom: 0;
        //   }
        //   &.right {
        //     right: -20%;
        //   }
        // }
        div.member-list {
            justify-content: center;
            display: flex;
            @media screen and (min-device-width: 1200px) {
                margin: 14rem auto 0;
                width: 100rem;
                .first-item {
                    width: 24.4rem !important;
                    margin: 0 1rem;
                }
                .second-item {
                    width: 18rem !important;
                    margin: 0 1.3rem;
                }
            }
            @media screen and (max-device-width: 1199px) {
                margin: 13.3333vw auto 0;
                width: 85.8667vw;
                .first-item{
                    margin: 0 1.3333vw;
                    width: 41.6vw;
                }
            }
            .item {
                cursor: pointer;
            }
        }

        div.custom-title{
            font-weight: bold;
            margin: 0;
            @media screen and (min-device-width: 1200px) {
                margin-top: 11.8rem;
                font-size: 3.6rem;
            }
            @media screen and (max-device-width: 1199px) {
                margin-top: 21.0667vw;
                font-size: 3.7333vw;
                &.EN{
                    margin-top: 13.0667vw;
                    font-size: 4.8vw;
                }
            }
        }
        div.custom-content{
            color: #3D3D3D;
            text-align: justify;
            @media screen and (min-device-width: 1200px) {
                line-height: 3.6rem;
                margin-top: 4.4rem;
                font-size: 2.4rem;
                width: 85.6rem;
                &.EN{
                    margin-top: 3.4rem;
                    font-size: 2.2rem;
                    line-height: 3rem;
                    text-align: left;
                    width: 92rem;
                }
            }
            @media screen and (max-device-width: 1199px) {
                margin-top: 7.7333vw;
                font-size: 3.7333vw;
                line-height: 5vw;
                width: 74.9333vw;
                &.EN{
                    margin-top: 3.2vw;
                    text-align: left;
                    font-size: 3.2vw;
                    // width: 92rem;
                }
            }
        }
    }
}
</style>
